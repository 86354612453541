// Generated by Framer (575ceb6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["sbm96oQPj"];

const variantClassNames = {sbm96oQPj: "framer-v-m6pfn1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "sbm96oQPj", title: ZQd4EHx7T = "Read more", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "sbm96oQPj", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-8Na9i", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-m6pfn1", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"sbm96oQPj"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0YzNyBOZXVybyBSZWd1bGFy", "--framer-font-family": "\"F37 Neuro Regular\", sans-serif", "--framer-font-size": "17px", "--framer-line-height": "1.6em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv)", "--framer-text-decoration": "underline"}}>Read more</motion.p></React.Fragment>} className={"framer-tne158"} fonts={["CUSTOM;F37 Neuro Regular"]} layoutDependency={layoutDependency} layoutId={"Jaeh4MbWE"} style={{"--extracted-r6o4lv": "var(--token-085607d2-ba18-40c0-ae5b-decfdfdcecdc, rgb(37, 91, 215))", "--framer-paragraph-spacing": "0px"}} text={ZQd4EHx7T} transformTemplate={transformTemplate} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-8Na9i [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8Na9i .framer-139ghkg { display: block; }", ".framer-8Na9i .framer-m6pfn1 { height: 27px; overflow: hidden; position: relative; width: 262px; }", ".framer-8Na9i .framer-tne158 { flex: none; height: auto; left: 0px; overflow: visible; position: absolute; right: 0px; top: 48%; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 262
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ZQd4EHx7T":"title"}
 */
const Framerb6uaZqnc2: React.ComponentType<Props> = withCSS(Component, css, "framer-8Na9i") as typeof Component;
export default Framerb6uaZqnc2;

Framerb6uaZqnc2.displayName = "Read More Link";

Framerb6uaZqnc2.defaultProps = {height: 27, width: 262};

addPropertyControls(Framerb6uaZqnc2, {ZQd4EHx7T: {defaultValue: "Read more", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerb6uaZqnc2, [{family: "F37 Neuro Regular", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/b6uaZqnc2:default", url: "assets/cE8Er73InR67th9PSgG7yLH64.otf"}, url: new URL("assets/cE8Er73InR67th9PSgG7yLH64.otf", import.meta.url).href}])